import { Col, Row, Table, Button, Card, Input } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";
import CustomModal from "../../components/CustomModal";
import { notifyError, notifySuccess } from "../../common/notification";
import moment from "moment";
const { Search } = Input;
let accessLock = false;

class index extends Component {
  state = {
    actionId: "",
  };

  componentDidMount() {
    this.props.loadSpinTheWheelData();
    if (this?.props?.admin?.access_lock == 1) {
      accessLock = true;
    }
  }

  componentWillReceiveProps() {
    if (this?.props?.admin?.access_lock == 1) {
      accessLock = true;
    }
  }

  onSearch = (value) => {
    this.props.loadSpinTheWheelData(value);
  };

  // Render the card info in table formate
  columns = [
    {
      title: "#",
      dataIndex: "user_spin_the_wheel_data_id",
      key: "user_spin_the_wheel_data_id",
      render: (_text, record) => (
        <p className="brand-name">{record.sr_no || ""}</p>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (_text, record) => (
        <p className="brand-name">
          {accessLock ? "******************" : record.u_email_id}
        </p>
      ),
    },
    {
      title: "Amount won",
      dataIndex: "spin_result",
      key: "spin_result",
      render: (_text, record) => (
        <p className="brand-name">{Math.abs(record.spin_result) || ""}</p>
      ),
    },
    {
      title: "Date created",
      dataIndex: "date_created",
      key: "date_created",
      render: (_text, record) => (
        <p className="brand-name">
          {moment(record.date_created, "YYYY-MM-DD HH:mm:ss").format(
            "MM-DD-YYYY"
          ) || ""}
        </p>
      ),
    },
    {
      title: "Cashback balance(before spin)",
      dataIndex: "current_cashback_balance",
      key: "current_cashback_balance",
      render: (_text, record) => (
        <p className="brand-name">{record.current_cashback_balance || ""}</p>
      ),
    },
    {
      title: "Coin balance(before spin)",
      dataIndex: "current_coin_balance",
      key: "current_coin_balance",
      render: (_text, record) => (
        <p className="brand-name">{record?.current_coin_balance || ""}</p>
      ),
    },
  ];

  render() {
    const { spinTheWheelData, spinTheWheelCount } = this.props;
    let results = [];
    if (spinTheWheelData && spinTheWheelData.length > 0) {
      for (var i = 0; i < spinTheWheelData.length; i++) {
        var key = `user-spin-the-wheel-${spinTheWheelData[i].user_spin_the_wheel_data_id}`;

        results.push({
          sr_no: i + 1,
          key: key,
          ...spinTheWheelData[i],
        });
      }
    }

    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={14}>
            <h4>Spin the wheel</h4>
            <Search
              placeholder="Search..."
              onSearch={this.onSearch}
              style={{
                width: 400,
                paddingLeft: 10,
              }}
              enterButton
              size="large"
            />
          </Col>

          <Col md={10} className="custom-flex-end">
            <Row className="card-wrap">
              <Card title="Total spins in the app!" className="custom-card">
                <h4>{spinTheWheelCount || 0}</h4>
              </Card>
            </Row>
          </Col>
        </Row>
        <Row className="card-table partner-table">
          <Table
            sortOrder={false}
            dataSource={results}
            columns={this.columns}
          />
        </Row>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    spinTheWheelData: state.spinTheWheelData,
    spinTheWheelCount: state.spinTheWheelCount,
    admin: state.adminInfo,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
