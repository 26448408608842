import { Col, Row, Table, Button } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";
import CustomModal from "../../components/CustomModal";
import { notifyError, notifySuccess } from "../../common/notification";
let accessLock = false;

class index extends Component {
  state = {
    councilTaxData: [],
    deleteModal: false,
    deleteData: {},
    addModal: false,
    addData: {},
  };
  async componentDidMount() {
    if (this?.props?.admin?.access_lock == 1) {
      accessLock = true;
    }
    if (this?.props?.location?.state?.data) {
      this.setState({ councilTaxData: this?.props?.location?.state?.data });
    } else {
      await this.props.loadUserDashboardMiscData();
      this.setState({
        councilTaxData:
          this?.props?.userMiscDataDashoard?.potentialIncomeData || [],
      });
    }
  }

  componentWillReceiveProps() {
    if (this?.props?.admin?.access_lock == 1) {
      accessLock = true;
    }
  }

  submitUserAction = (action) => {
    if (action == "add") {
      this.props
        .updatePotentialData({
          data: this.state.addData,
          type: "add",
        })
        .then(async (res) => {
          if (res && res.data && res.data.status) {
            this.setState({
              addModal: false,
              addData: {},
            });
            notifySuccess(res?.data?.message || "Done");
            await this.props.loadUserDashboardMiscData();
            this.setState({
              councilTaxData:
                this?.props?.userMiscDataDashoard?.potentialIncomeData || [],
            });
          } else {
            var errorMessage =
              res?.data?.message || res?.err?.message || "Something went wrong";
            notifyError(errorMessage);
          }
        });
    } else {
      this.props
        .updatePotentialData({
          id: this.state.deleteData.misc_information_table_id,
        })
        .then(async (res) => {
          if (res && res.data && res.data.status) {
            this.setState({
              deleteModal: false,
              deleteData: {},
            });
            notifySuccess(res?.data?.message || "Done");
            await this.props.loadUserDashboardMiscData();
            this.setState({
              councilTaxData:
                this?.props?.userMiscDataDashoard?.potentialIncomeData || [],
            });
          } else {
            var errorMessage =
              res?.data?.message || res?.err?.message || "Something went wrong";
            notifyError(errorMessage);
          }
        });
    }
  };

  handleDeleteModal = (data) => {
    if (accessLock) {
      notifyError("You do not have permission to access this.");
      return false;
    }
    const { deleteModal } = this.state;
    this.setState({
      deleteData: data ? data : {},
      deleteModal: !deleteModal,
    });
  };

  handleAddModal = (data) => {
    if (accessLock) {
      notifyError("You do not have permission to access this.");
      return false;
    }
    const { addModal } = this.state;
    this.setState({
      addData: data ? data : {},
      addModal: !addModal,
    });
  };

  // Render the card info in table formate
  columns = [
    {
      title: "User ID",
      dataIndex: "_user_id",
      key: "_user_id",
      render: (_text, record) => (
        <p
          style={{ cursor: "pointer" }}
          onClick={(event) => {
            const { history } = this.props;
            if (event.ctrlKey || event.metaKey) {
              // Open in a new tab
              window.open(`#/admin/users/${record._user_id}`, "_blank");
            } else {
              // Navigate in the same tab
              history.push(`/admin/users/${record._user_id}`);
            }
          }}
        >
          {record._user_id || ""}
        </p>
      ),
    },
    {
      title: "User Email",
      dataIndex: "user_email",
      key: "user_email",
      render: (_text, record) => (
        <p>{accessLock ? "****************" : record.user_email}</p>
      ),
    },
    {
      title: "Data set",
      dataIndex: "misc_data_one",
      key: "misc_data_one",
      render: (_text, record) => (
        <pre style={{ fontSize: "15px" }}>
          {JSON.stringify(JSON.parse(record?.misc_data_one), null, 2)}
        </pre>
      ),
    },
    {
      title: "Actions",
      key: "action",
      render: (_text, record) => {
        return (
          <div style={{ width: "300px" }} className="btn-actions">
            <span
              style={{ marginRight: "16px" }}
              onClick={() => {
                this.handleDeleteModal(record);
              }}
            >
              Delete entry
            </span>

            <span
              onClick={() => {
                this.handleAddModal(record);
              }}
            >
              Add as user income
            </span>
          </div>
        );
      },
    },
  ];

  render() {
    const { councilTaxData, deleteModal, addModal } = this.state;
    let results = [];
    // console.log("councilTaxData ==>", councilTaxData);
    if (councilTaxData && councilTaxData.length > 0) {
      for (var i = 0; i < councilTaxData.length; i++) {
        var key = `user-potential-income-data-${i}`;

        results.push({
          sr_no: i + 1,
          key: key,
          ...councilTaxData[i],
        });
      }
    }

    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>Potential Income data.</h4>
          </Col>
        </Row>
        <Row className="card-table">
          <Table
            dataSource={results}
            columns={this.columns}
            sortOrder={false}
            pagination={false}
          />
        </Row>
        <CustomModal
          className={"signup-setting-modal"}
          visible={deleteModal}
          onClose={this.handleDeleteModal}
        >
          <p className="txt-title">Delete this entry?</p>
          <p className="txt-info">This change cannot be undone.</p>

          <Button type="primary" block onClick={() => this.submitUserAction()}>
            Delete
          </Button>
        </CustomModal>

        <CustomModal
          className={"signup-setting-modal"}
          visible={addModal}
          onClose={this.handleAddModal}
        >
          <p className="txt-title">Add this entry as income for the user?</p>
          <Button
            type="primary"
            block
            onClick={() => this.submitUserAction("add")}
          >
            Add
          </Button>
        </CustomModal>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    userMiscDataDashoard: state.userMiscDataDashoard,
    admin: state.adminInfo,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
