import { Button, Col, Row, Card, Input, Form } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import moment from "moment";
import CustomModal from "../../components/CustomModal";
import { notifyError, notifySuccess } from "../../common/notification";
import {
  formatPrice,
  trimAccountNumber,
  getGender,
  getSituation,
  livePlaces,
  livingSituation,
  housingStatusList,
  currentHouseHoldList,
  employmentStatus,
  reasonOfUsing,
  financialDifficulties,
  currentlyManagingRent,
  allFactorsList,
  behindPaymentsList,
  bestDescribesList,
} from "../../common/utils";
import "./indexDetailsPage.less";
let accessLock = false;
// Shows the user datails
class details extends Component {
  state = {
    pauseUserModal: false,
    deleteUserModal: false,
    accountInfoModal: false,
    userAction: null,
    selectedUserId: null,
    selectedAccount: null,
    isUserLock: true,
    isAddressLock: true,
    isBalanceLock: [],
    isOverdraftBalanceLock: [],
    isKlarnaBalanceLock: [],
    isOtherLoanLock: [],
    editBalanceModal: false,
    editCommentModal: false,
    processedUserBillList: [],
  };

  async componentDidMount() {
    if (this?.props?.admin?.access_lock == 1) {
      accessLock = true;
    }
    // get user id from url
    const { match } = this.props;
    const { params } = match;
    const { userId } = params;
    this.setState({ selectedUserId: userId });
    this.props.loadUserInfo(userId);
    this.props.loadPartnerOnboardingCodes();

    const userBills = await this.props.UserBills(userId); // Ensure this returns the correct data
    await this.processUserBillList(userBills?.data?.data);
    // this.props.loadUserLiveInfo(userId);
    // console.log("Fetched User Bills:", userBills);
    // this.props.UserBills(userId);
  }

  componentWillReceiveProps() {
    if (this?.props?.admin?.access_lock == 1) {
      accessLock = true;
    }
  }

  processUserBillList = async (userBillList) => {
    // console.log("User Bill List Before Processing:", userBillList);
    if (userBillList) {
      const result = await Promise.all(
        userBillList.map((data) => {
          const status = this.getStatus(data);
          return {
            ...data,
            status,
          };
        })
      );
      // console.log("Processed User Bill List:", result); // Check processed output
      this.setState({ processedUserBillList: result });
    } else {
      this.setState({ processedUserBillList: [] });
    }
  };

  // Shows the pause/unpause pop up for the particular user
  handlePauseModal = (action, userId) => {
    if (accessLock) {
      notifyError("You do not have permission to for this action.");
      return false;
    }
    const { pauseUserModal } = this.state;
    this.setState({
      pauseUserModal: !pauseUserModal,
      userAction: action,
      selectedUserId: userId,
    });
  };

  launchSync = (email, userId) => {
    if (accessLock) {
      notifyError("You do not have permission to for this action.");
      return false;
    }
    this.props.updateUserBillTrackerSync({ email, userId }).then((res) => {
      if (res && res.data && res.data.status) {
        notifySuccess("Sucess, please refresh page once.");
      } else {
        var errorMessage =
          res?.data?.message || res?.err?.message || "Something went wrong";
        notifyError(errorMessage);
      }
    });
  };

  // Shows the delete user pop up
  handleDeleteModal = (userId) => {
    if (accessLock) {
      notifyError("You do not have permission to for this action.");
      return false;
    }
    const { deleteUserModal } = this.state;
    this.setState({
      deleteUserModal: !deleteUserModal,
      selectedUserId: userId,
    });
  };

  // Shows the delete user pop up
  handleEditBalanceModal = () => {
    if (accessLock) {
      notifyError("You do not have permission to for this action.");
      return false;
    }
    const { editBalanceModal } = this.state;
    this.setState({
      editBalanceModal: !editBalanceModal,
    });
  };

  //edit comment Modal flag
  handleEditComment = () => {
    const { editCommentModal } = this.state;
    this.setState({
      editCommentModal: !editCommentModal,
    });
  };

  submitForm = (values) => {
    const { selectedUserId } = this.state;
    var data = {
      action: "coin_balance_update",
      user_coin_balance: values.user_coin_balance,
      user_cashback_balance: values.user_cashback_balance,
    };

    this.props.updateUserAction(selectedUserId, data).then((res) => {
      if (res && res.data && res.data.status) {
        this.setState({ editBalanceModal: false });
        notifySuccess(res?.data?.message || "Done");
        this.props.loadUserInfo(selectedUserId);
      } else {
        var errorMessage =
          res?.data?.message || res?.err?.message || "Something went wrong";
        notifyError(errorMessage);
      }
    });
  };

  submitComment = (values) => {
    const { selectedUserId } = this.state;
    var data = {
      action: "comment_update",
      comment: values.user_comment,
      id: selectedUserId,
      // comment_id: props.comment_id,
    };

    this.props.updateUsercomment(data).then((res) => {
      if (res && res.data && res.data.status) {
        this.setState({ editCommentModal: false });
        notifySuccess(res?.data?.message || "Done");
        this.props.loadUserInfo(selectedUserId);
      } else {
        var errorMessage =
          res?.data?.message || res?.err?.message || "Something went wrong";
        notifyError(errorMessage);
      }
    });
  };

  // Shows the acccount info
  displayAccountInfo = (account) => {
    const { accountInfoModal } = this.state;
    this.setState({
      selectedAccount: account,
      accountInfoModal: !accountInfoModal,
    });
  };

  // Update user info
  submitUserAction = (action) => {
    const { selectedUserId } = this.state;
    var data = { action: action };

    this.props.updateUserAction(selectedUserId, data).then((res) => {
      if (res && res.data && res.data.status) {
        this.setState({ pauseUserModal: false, deleteUserModal: false });
        notifySuccess(res?.data?.message || "Done");
        this.props.loadUserInfo(selectedUserId);
        if (action === "delete") this.props.history.goBack();
      } else {
        var errorMessage =
          res?.data?.message || res?.err?.message || "Something went wrong";
        notifyError(errorMessage);
      }
    });
  };

  // Get the price of the user account
  getPriceInfo = (data) => {
    var price = 0;
    if (data?.account_details?.current_balance !== undefined) {
      price = data?.account_details?.current_balance;
    } else if (data?.account_details?.balance_info?.current !== undefined) {
      price = data?.account_details?.balance_info?.current;
    }
    return price;
  };

  // Get the price of the klarna account
  getKlaranaPriceInfo = (data) => {
    var price = 0;
    if (data?.payment_schedule === "Pay in 30 days") {
      price = data?.price_of_purchase;
    } else if (data?.payment_installments_details?.installments?.length > 0) {
      // let newdata = data?.payment_installments_details?.installments?.map(
      //   (item) => {
      //     if (item.completed === false) {
      //       price += item.installment_amount;
      //     }
      //   }
      // );
    } else if (data?.payment_installments_details?.current_balance) {
      price = data?.payment_installments_details?.current_balance;
    }
    return price;
  };

  // Get formated string
  getDateString = (date) => {
    var dateString = "-";
    if (date) {
      dateString = `Visited ${moment(date).format("DD/MM/YYYY")}`;
    }
    return dateString;
  };

  // Get user onboarding steps
  getUserStep = () => {
    var step = "Sign up";
    const { active_user } = this.props;
    let user_progress = active_user?.onboarding?.user_progress;
    if (
      active_user?.info?.onboarding_complete == 1 &&
      (active_user?.credit_cards.length > 0 ||
        active_user?.overdraft_account.length > 0 ||
        active_user?.klarna_account.length > 0)
    ) {
      step = "Onboarding Complete";
    } else if (
      active_user?.info?.onboarding_complete == 1 &&
      active_user?.credit_cards.length == 0 &&
      active_user?.overdraft_account.length == 0 &&
      active_user?.klarna_account.length == 0
    ) {
      step = "Homescreen - No accounts connected";
    } else if (
      active_user?.info?.onboarding_complete == 0 &&
      user_progress?.step_5 !== undefined &&
      user_progress?.step_5["Question 1"] !== "" &&
      user_progress?.step_5["Question 2"] !== "" &&
      user_progress?.step_5["Question 3"] !== "" &&
      user_progress?.step_5["Question 4"] !== "" &&
      user_progress?.superFiNotForYou === 1
    ) {
      step = "Outcome step #2 - SuperFi not right for you";
    } else if (
      user_progress?.step_4 !== undefined &&
      user_progress?.step_4?.gender !== "" &&
      user_progress?.step_4?.bestDescribes !== "" &&
      user_progress?.step_4?.surname !== "" &&
      user_progress?.step_4?.concerned !== "" &&
      user_progress?.step_4?.first_name !== "" &&
      user_progress?.step_4?.dateOfBirth !== ""
    ) {
      const date_of_birth = moment(
        user_progress?.step_4?.dateOfBirth,
        "YYYY-MM-DD HH:mm:ss"
      );
      var userAge = moment().diff(date_of_birth, "years");

      if (userAge < 18) {
        step = "SuperFi not suitable - under 18";
      } else if (
        user_progress?.step_4?.concerned == "not_concerned" ||
        user_progress?.step_5 !== undefined
      ) {
        step = "About you #2 - Questions";
      } else {
        let reason = user_progress?.step_4?.concerned?.split("_");
        reason = reason?.join(" ");
        step =
          user_progress?.step_4?.concerned == "eviction_repossessions_bayliffs"
            ? "SuperFi not suitable - bailiff action"
            : `Outcome step #1 - ${reason}`;
      }
    } else if (user_progress?.step_3 !== undefined) {
      step = "About you #1 - Personal details";
    } else if (user_progress?.step_2 !== undefined) {
      step = "Passcode";
    } else if (user_progress?.step_1 !== undefined) {
      step = "Notification preferences";
    }

    if (step == "Sign up" && active_user?.info?.is_email_verified == 1) {
      step = "Email verified";
    }
    return step;
  };

  getSortCode = (selectedAccount) => {
    var sort_code = "N/A";

    if (selectedAccount) {
      if (
        selectedAccount.account_details &&
        selectedAccount.account_details.account_number &&
        selectedAccount.account_details.account_number.sort_code
      ) {
        sort_code = selectedAccount.account_details.account_number.sort_code;
      } else if (selectedAccount.sort_code) {
        sort_code = selectedAccount.sort_code;
      }
    }
    return sort_code;
  };
  getAccountNumber = (selectedAccount) => {
    var number = "N/A";

    if (selectedAccount) {
      if (
        selectedAccount.account_details &&
        selectedAccount.account_details.account_number &&
        selectedAccount.account_details.account_number.number
      ) {
        number = selectedAccount.account_details.account_number.number;
      } else if (selectedAccount.account_number) {
        number = selectedAccount.account_number;
      }
    }
    return number;
  };

  getPanNumber = (data) => {
    var number = "N/A";
    if (data) {
      var test = data;
      const num =
        test.substr(0, test.length - 5).replace(/[0-9]/g, "X") +
        test.substr(test.length - 5, test.length);
      number = num;
    }
    return number;
  };

  handleUserLockClick = (props) => {
    if (accessLock) {
      notifyError("You do not have permission to access this information.");
      return false;
    }
    this.setState({ isUserLock: !this.state.isUserLock });
  };

  handleAddressLockClick = (props) => {
    if (accessLock) {
      notifyError("You do not have permission to access this information.");
      return false;
    }
    this.setState({ isAddressLock: !this.state.isAddressLock });
  };

  handleBalanceLockClick = (props) => {
    if (accessLock) {
      notifyError("You do not have permission to access this information.");
      return false;
    }
    const { isBalanceLock } = this.state;
    if (isBalanceLock.includes(props)) {
      this.setState((prevState) => ({
        isBalanceLock: prevState.isBalanceLock.filter((item) => item !== props),
      }));
    } else {
      this.setState((prevState) => ({
        isBalanceLock: [...prevState.isBalanceLock, props],
      }));
    }
  };

  handleOverdraftBalanceLockClick = (props) => {
    if (accessLock) {
      notifyError("You do not have permission to access this information.");
      return false;
    }
    const { isOverdraftBalanceLock } = this.state;
    if (isOverdraftBalanceLock.includes(props)) {
      this.setState((prevState) => ({
        isOverdraftBalanceLock: prevState.isOverdraftBalanceLock.filter(
          (item) => item !== props
        ),
      }));
    } else {
      this.setState((prevState) => ({
        isOverdraftBalanceLock: [...prevState.isOverdraftBalanceLock, props],
      }));
    }
  };

  handleKlarnaBalanceLockClick = (props) => {
    if (accessLock) {
      notifyError("You do not have permission to access this information.");
      return false;
    }
    const { isKlarnaBalanceLock } = this.state;
    if (isKlarnaBalanceLock.includes(props)) {
      this.setState((prevState) => ({
        isKlarnaBalanceLock: prevState.isKlarnaBalanceLock.filter(
          (item) => item !== props
        ),
      }));
    } else {
      this.setState((prevState) => ({
        isKlarnaBalanceLock: [...prevState.isKlarnaBalanceLock, props],
      }));
    }
  };

  handleOtherDebtLockClick = (props) => {
    if (accessLock) {
      notifyError("You do not have permission to access this information.");
      return false;
    }
    const { isOtherLoanLock } = this.state;
    if (isOtherLoanLock.includes(props)) {
      this.setState((prevState) => ({
        isOtherLoanLock: prevState.isOtherLoanLock.filter(
          (item) => item !== props
        ),
      }));
    } else {
      this.setState((prevState) => ({
        isOtherLoanLock: [...prevState.isOtherLoanLock, props],
      }));
    }
  };

  handleDownloadCSV = (data) => {
    if (data?.incomeExpenseFormCondition) {
      window.open(data?.incomeExpenseFormInfo?.csv, "_blank");
    } else {
      return false;
    }
  };

  getStatus = (item) => {
    const dateFormat = "YYYY-MM-DD HH:mm:ss";
    const predictedDate = moment(item?.timestamp, dateFormat);
    const paidDate = moment(item?.actual_timestamp, dateFormat);
    const isPaid = predictedDate.isSame(paidDate, "date");
    if (item?.adminStatus == "overdue") {
      return <span style={{ color: "red" }}>Overdue</span>;
    } else if (item?.unlikelyToAfford) {
      return <span style={{ color: "red" }}>Unlikely to afford</span>;
    } else if (isPaid && item?.reduce_bill_flag) {
      return "Paid (Reduce bill)";
    } else if (isPaid) {
      return "Paid";
    } else if (item?.reduce_bill_flag) {
      return "Unpaid (Reduce bill)";
    } else {
      return "Unpaid";
    }
  };

  render() {
    const { TextArea } = Input;
    let { active_user, partnerOnboardingCodes } = this.props;
    const { processedUserBillList } = this.state;
    // userBillList = [
    //   {
    //     "transaction_id": "722e0249-5c81-dc03-09d9-7bc2019d111f",
    //     "display_name": "SPILLER NPH MOVE BGC",
    //     "actual_timestamp": "2024-07-01 00:00:00",
    //     "timestamp": "2024-10-01 00:00:00",
    //     "amount": 1219.98,
    //     "currency": "GBP",
    //     "bank_provider_id": "ob-barclays",
    //     "provider_card_id": "",
    //     "provider_account_id": 781,
    //     "truelayer_category": [],
    //     "bill_tracker_add_bill_id": 97,
    //     "bank_id": 8,
    //     "truelayer_account_id": "f0ee522d745f0afe6e854c6268f57c7a",
    //     "truelayer_card_id": null,
    //     "add_type": "Income",
    //     "reduce_bill_flag": false,
    //     "screen_type": null
    //   }
    // ]

    const {
      pauseUserModal,
      deleteUserModal,
      accountInfoModal,
      selectedAccount,
      userAction,
      isUserLock,
      isAddressLock,
      isBalanceLock,
      isOverdraftBalanceLock,
      isKlarnaBalanceLock,
      isOtherLoanLock,
      editBalanceModal,
      editCommentModal,
    } = this.state;
    var action = active_user?.info?.status === "active" ? "Pause" : "Unpause";
    var accountIndex = 1;

    let overdraft_account = active_user?.overdraft_account;
    let other_debts = active_user?.other_debts || [];

    // let cardDebtSuggestion =
    //   active_user?.calculationDebt?.[0]?.superfi_debt_calculation_details?.[
    //     "avalanche"
    //   ]?.cards_accounts ||
    //   active_user?.calculationDebt?.[0]?.superfi_debt_calculation_details?.[
    //     "snowball"
    //   ]?.cards_accounts ||
    //   [];

    // let klarnaDebtSuggestion =
    //   active_user?.calculationDebt?.[0]?.superfi_debt_calculation_details?.[
    //     "avalanche"
    //   ]?.non_calculation_accounts ||
    //   active_user?.calculationDebt?.[0]?.superfi_debt_calculation_details?.[
    //     "snowball"
    //   ]?.non_calculation_accounts ||
    //   [];

    if (active_user?.credit_cards) {
      active_user.credit_cards = active_user?.credit_cards?.map((item) => {
        const isValueInNewValue = isBalanceLock?.some(
          (subItem) => item.user_card_id === subItem
        );
        item.isLock = isValueInNewValue;
        return item;
      });
    }

    if (overdraft_account) {
      overdraft_account = overdraft_account?.map((item) => {
        const isValueInNewValue = isOverdraftBalanceLock?.some(
          (subItem) => item.user_overdraft_account_id === subItem
        );
        item.isLock = isValueInNewValue;
        return item;
      });
    }

    if (other_debts) {
      other_debts = other_debts?.map((item) => {
        const isValueInNewValue = isOtherLoanLock?.some(
          (subItem) => item.priority_non_priority_debts_id === subItem
        );
        item.isLock = isValueInNewValue;
        return item;
      });
    }

    if (active_user?.klarna_account) {
      active_user.klarna_account = active_user?.klarna_account?.map((item) => {
        const isValueInNewValue = isKlarnaBalanceLock?.some(
          (subItem) => item.klarna_id === subItem
        );
        item.isLock = isValueInNewValue;
        return item;
      });
    }
    let favBrandsData = active_user?.fav_brand_data?.misc_info
      ? JSON.parse(active_user?.fav_brand_data?.misc_info)
      : {};

    let partnerName = partnerOnboardingCodes.find(
      (data) =>
        data.app_partner_onboarding_codes_id == active_user?.info?._partner_id
    );

    return (
      <BasicLayout className="custom-card-right">
        {active_user && processedUserBillList && (
          <>
            <Row className="section-title">
              {active_user.info && (
                <>
                  <Col md={14} className="title-wrap">
                    <h4>User Details</h4>
                  </Col>
                  <Col md={10}>
                    <Row gutter={[15, 20]} className="card-wrap">
                      <Col xs={24} md={6}>
                        <Button
                          onClick={() => {
                            const { history } = this.props;
                            history.push(
                              `/admin/users/${active_user.info.user_id}/gift-logs`
                            );
                          }}
                          className="custom-card"
                          type="default"
                        >
                          View Gift Logs
                        </Button>
                      </Col>
                      <Col xs={24} md={6}>
                        <Button
                          onClick={() => {
                            window.open(
                              `https://app.intercom.com/a/apps/l6pto2lh/users/${active_user?.info?.intercom_reference_id}`,
                              "_blank"
                            );
                          }}
                          className="custom-card"
                          type="default"
                        >
                          Intercom Profile
                        </Button>
                      </Col>
                      <Col xs={24} md={6}>
                        <Card
                          title="No. Sessions last 30 days"
                          className="custom-card"
                        >
                          <h4>{active_user.totalSession || "NA"}</h4>
                        </Card>
                      </Col>
                      <Col xs={24} md={6}>
                        <Card
                          title="Avg session length"
                          className="custom-card"
                        >
                          <h4>{active_user.averageTime || "0:00"}</h4>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                  <Col md={24}>
                    <div className="ant-table-wrapper">
                      <div className="ant-table">
                        <table>
                          <thead className="ant-table-thead">
                            <tr>
                              <th className="ant-table-cell">Email</th>
                              <th className="ant-table-cell"></th>
                              <th className="ant-table-cell">Partner name</th>
                              <th className="ant-table-cell">Verified</th>
                              <th className="ant-table-cell">
                                Opted for market research
                              </th>
                              <th className="ant-table-cell">Date joined</th>
                              <th className="ant-table-cell">Last active</th>
                              <th className="ant-table-cell">User number</th>
                              <th className="ant-table-cell">Gender</th>
                              <th className="ant-table-cell">Email pref</th>
                              <th className="ant-table-cell">Device</th>
                              <th className="ant-table-cell">Actions</th>
                            </tr>
                          </thead>
                          <tbody className="ant-table-tbody">
                            <tr className="ant-table-row">
                              <td className="ant-table-cell">
                                <div style={{ width: 220 }}>
                                  {isUserLock ? (
                                    <div>
                                      <img
                                        src={"/images/Redact.png"}
                                        alt="SuperFi logo"
                                        style={{ width: 210, height: 20 }}
                                      />
                                    </div>
                                  ) : (
                                    active_user?.info?.u_email_id || ""
                                  )}
                                </div>
                                {/* {active_user?.info?.u_email_id || ""} */}
                              </td>
                              <td className="ant-table-cell">
                                <div>
                                  <span
                                    onClick={(e) => this.handleUserLockClick()}
                                  >
                                    <img
                                      src={
                                        isUserLock
                                          ? "/images/lock.png"
                                          : "/images/unlock.png"
                                      }
                                      alt="SuperFi logo"
                                      style={{ width: 16.66, height: 20 }}
                                    />
                                  </span>
                                </div>
                              </td>
                              <td>
                                {partnerName ? partnerName.partner_name : ""}
                              </td>
                              <td className="ant-table-cell">
                                {active_user?.info?.is_email_verified
                                  ? "Yes"
                                  : "No"}
                              </td>

                              <td className="ant-table-cell">
                                {active_user?.info?.opt_market_research == 1
                                  ? "Yes"
                                  : "No"}
                              </td>

                              <td className="ant-table-cell">
                                {moment(active_user?.info?.date_created).format(
                                  "DD/MM/YYYY"
                                )}
                              </td>
                              <td className="ant-table-cell">
                                {moment(
                                  active_user?.info?.last_login_date ||
                                    active_user?.info?.date_created
                                ).format("DD/MM/YYYY")}
                              </td>
                              <td className="ant-table-cell">
                                #{active_user?.info?.user_unique_id || ""}
                              </td>
                              <td className="ant-table-cell">
                                {getGender(active_user?.info?.gender) || "-"}
                              </td>
                              <td className="ant-table-cell">
                                {active_user?.info?.user_preference_setting
                                  ?.marketing_preferences
                                  ? "Y"
                                  : "N"}
                              </td>
                              <td className="ant-table-cell">
                                {active_user?.info?.device_name || ""}
                              </td>
                              <td className="ant-table-cell">
                                <div className="btn-actions">
                                  <span
                                    style={{
                                      marginRight: 16,
                                    }}
                                    onClick={(e) =>
                                      this.handleDeleteModal(
                                        active_user?.info?.user_id
                                      )
                                    }
                                  >
                                    Delete
                                  </span>
                                  <span
                                    style={{
                                      marginRight: 16,
                                    }}
                                    onClick={(e) =>
                                      this.handlePauseModal(
                                        action,
                                        active_user?.info?.user_id
                                      )
                                    }
                                  >
                                    {action}
                                  </span>
                                  <span
                                    onClick={(e) =>
                                      this.launchSync(
                                        active_user?.info?.u_email_id,
                                        active_user?.info?.user_id
                                      )
                                    }
                                  >
                                    Bill sync
                                  </span>
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </Col>
                  <Col md={24}>
                    {" "}
                    <Col md={24}>
                      <div className="ant-table-wrapper">
                        <div className="ant-table">
                          <table>
                            <thead className="ant-table-thead">
                              <tr>
                                <th className="ant-table-cell">Address</th>
                                <th className="ant-table-cell"></th>
                              </tr>
                            </thead>
                            <tbody className="ant-table-tbody">
                              <tr className="ant-table-row">
                                <td className="ant-table-cell">
                                  <div style={{ width: 500 }}>
                                    {isAddressLock &&
                                    (active_user?.info?.house_number ||
                                      active_user?.info?.address_line_1 ||
                                      active_user?.info?.address_line_2 ||
                                      active_user?.info?.city ||
                                      active_user?.info?.postcode) ? (
                                      <div>
                                        <img
                                          src={"/images/Redact.png"}
                                          alt="SuperFi logo"
                                          style={{ width: "100%", height: 20 }}
                                        />
                                      </div>
                                    ) : (
                                      <>
                                        {active_user?.info?.house_number
                                          ? active_user?.info?.house_number +
                                            ", "
                                          : ""}
                                        {active_user?.info?.address_line_1
                                          ? active_user?.info?.address_line_1 +
                                            ", "
                                          : ""}

                                        {active_user?.info?.address_line_2
                                          ? active_user?.info?.address_line_2 +
                                            ", "
                                          : ""}
                                        {active_user?.info?.city
                                          ? active_user?.info?.city +
                                            (active_user?.info?.postcode
                                              ? ", "
                                              : "")
                                          : ""}
                                        {active_user?.info?.postcode
                                          ? active_user?.info?.postcode
                                          : ""}
                                      </>
                                    )}
                                  </div>
                                </td>
                                {active_user?.info?.house_number ||
                                active_user?.info?.address_line_1 ||
                                active_user?.info?.address_line_2 ||
                                active_user?.info?.city ||
                                active_user?.info?.postcode ? (
                                  <td className="ant-table-cell">
                                    <span
                                      onClick={(e) =>
                                        this.handleAddressLockClick()
                                      }
                                    >
                                      <img
                                        src={
                                          isAddressLock
                                            ? "/images/lock.png"
                                            : "/images/unlock.png"
                                        }
                                        alt="SuperFi logo"
                                        style={{ width: 16.66, height: 20 }}
                                      />
                                    </span>
                                  </td>
                                ) : null}
                              </tr>
                              <tr className="ant-table-row">
                                <td className="ant-table-cell">
                                  <div style={{ width: 500 }}>
                                    Council Tax Info :{" "}
                                    {active_user?.info?.council_tax_info
                                      ? JSON.parse(
                                          active_user?.info?.council_tax_info
                                        )?.council_name
                                      : ""}
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Col>
                  </Col>
                </>
              )}

              {active_user.personal_situation &&
                active_user.personal_situation.live_where && (
                  <>
                    <Col md={24}>
                      <h4>Personal situation</h4>
                    </Col>
                    <Col
                      md={24}
                      style={{
                        display: "block",
                        width: "100%",
                        overflowX: "auto",
                      }}
                    >
                      <div className="ant-table-wrapper table-custom-ant">
                        <div className="ant-table">
                          <table>
                            <thead className="ant-table-thead">
                              <tr>
                                <th className="ant-table-cell">
                                  Where do you live?
                                </th>
                                <th className="ant-table-cell">
                                  Living situation
                                </th>
                                <th className="ant-table-cell">
                                  Housing status
                                </th>
                                <th className="ant-table-cell">
                                  Current household
                                </th>
                                <th className="ant-table-cell">
                                  Main source of income
                                </th>
                                <th className="ant-table-cell">
                                  Reason for using SuperFi
                                </th>
                                <th className="ant-table-cell">
                                  Main cause of financial difficulties
                                </th>
                                <th className="ant-table-cell">
                                  How are you currently managing rent, bills &
                                  repayments?
                                </th>
                                <th className="ant-table-cell">
                                  Please choose all factors that affect you
                                </th>
                                <th className="ant-table-cell">
                                  Are you behind payments (in arrears) with any
                                  of these?
                                </th>
                                <th className="ant-table-cell">
                                  Which of these best describes you?
                                </th>
                                <th className="ant-table-cell">
                                  Recieving Government benefits?
                                </th>
                                <th className="ant-table-cell">
                                  Struggling with
                                </th>
                              </tr>
                            </thead>
                            <tbody className="ant-table-tbody">
                              <tr className="ant-table-row">
                                <td className="ant-table-cell">
                                  {getSituation(
                                    active_user?.personal_situation.live_where,
                                    livePlaces
                                  )}
                                </td>
                                <td className="ant-table-cell">
                                  {getSituation(
                                    active_user?.personal_situation
                                      .live_situation,
                                    livingSituation
                                  )}
                                </td>
                                <td className="ant-table-cell">
                                  {getSituation(
                                    active_user?.personal_situation
                                      .housing_status,
                                    housingStatusList
                                  )}
                                </td>
                                <td className="ant-table-cell">
                                  {getSituation(
                                    active_user?.personal_situation
                                      .current_household,
                                    currentHouseHoldList
                                  )}
                                </td>
                                <td className="ant-table-cell">
                                  {getSituation(
                                    active_user?.personal_situation
                                      .main_source_income,
                                    employmentStatus
                                  )}
                                </td>
                                <td className="ant-table-cell">
                                  {getSituation(
                                    active_user?.personal_situation
                                      .reason_using_superfi,
                                    reasonOfUsing
                                  )}
                                </td>
                                <td className="ant-table-cell">
                                  {getSituation(
                                    active_user?.personal_situation
                                      .main_case_financial,
                                    financialDifficulties
                                  )}
                                </td>

                                <td className="ant-table-cell">
                                  {getSituation(
                                    active_user?.personal_situation
                                      .currently_managing_rent,
                                    currentlyManagingRent
                                  )}
                                </td>

                                <td className="ant-table-cell">
                                  {getSituation(
                                    active_user?.personal_situation.all_factors,
                                    allFactorsList
                                  )}
                                </td>

                                <td className="ant-table-cell">
                                  {getSituation(
                                    active_user?.personal_situation
                                      .behind_payments,
                                    behindPaymentsList
                                  )}
                                </td>

                                <td className="ant-table-cell">
                                  {getSituation(
                                    active_user?.personal_situation
                                      .best_describes,
                                    bestDescribesList
                                  )}
                                </td>

                                <td className="ant-table-cell">
                                  {
                                    active_user?.personal_situation
                                      .receive_government_benefits
                                  }
                                </td>

                                <td className="ant-table-cell">
                                  {
                                    active_user?.personal_situation
                                      .struggling_with
                                  }
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </Col>
                  </>
                )}

              <Col md={24}>
                <h4>Accounts</h4>
              </Col>
              <Col md={24}>
                <div className="ant-table-wrapper ">
                  <div className="ant-table">
                    <table>
                      <thead className="ant-table-thead">
                        <tr>
                          <th className="ant-table-cell">#</th>
                          <th className="ant-table-cell">Accounts</th>
                          <th className="ant-table-cell">Balance</th>
                          <th className="ant-table-cell"></th>
                          <th className="ant-table-cell" width="100px">
                            Overdraft
                          </th>
                          <th className="ant-table-cell" width="100px">
                            Loan
                          </th>
                          <th className="ant-table-cell" width="100px">
                            Credit
                          </th>
                          <th className="ant-table-cell" width="100px">
                            BNPL
                          </th>
                          {/* <th className="ant-table-cell" width="100px">
                            Sug. towards
                          </th>
                          <th className="ant-table-cell" width="100px">
                            Put. towards
                          </th> */}
                          <th className="ant-table-cell">Status</th>
                        </tr>
                      </thead>
                      <tbody className="ant-table-tbody">
                        {active_user?.credit_cards &&
                          active_user.credit_cards.map((card, index) => (
                            <tr className="ant-table-row" key={index}>
                              <td className="ant-table-cell">
                                {accountIndex++}
                              </td>
                              <td
                                className="ant-table-cell custom-link underline-link"
                                onClick={() => this.displayAccountInfo(card)}
                              >
                                {card?.card_details?.provider?.display_name ||
                                  card?.card_details?.display_name ||
                                  ""}
                              </td>
                              <td>
                                {!card.isLock ? (
                                  <img
                                    src={"/images/Redact.png"}
                                    alt="SuperFi logo"
                                    style={{ width: 100, height: 20 }}
                                  />
                                ) : (
                                  <div>
                                    {formatPrice(
                                      card?.card_details?.current_balance ===
                                        0.0
                                        ? 0.0
                                        : -Math.abs(
                                            card?.card_details?.current_balance
                                          ),
                                      card?.card_details?.currency
                                    )}
                                  </div>
                                )}
                              </td>
                              <td className="ant-table-cell">
                                <div>
                                  <span
                                    onClick={(e) =>
                                      this.handleBalanceLockClick(
                                        card?.user_card_id
                                      )
                                    }
                                  >
                                    <img
                                      src={
                                        !card.isLock
                                          ? "/images/lock.png"
                                          : "/images/unlock.png"
                                      }
                                      alt="SuperFi logo"
                                      style={{ width: 16.66, height: 20 }}
                                    />
                                  </span>
                                </div>
                              </td>
                              <td className="ant-table-cell">N</td>
                              <td className="ant-table-cell">N</td>
                              <td className="ant-table-cell">Y</td>
                              <td className="ant-table-cell">N</td>
                              {/* <td className="ant-table-cell">
                                {getDebtSuggestionData(
                                  card,
                                  cardDebtSuggestion
                                ) || "- -"}
                              </td>
                              <td className="ant-table-cell">
                                {getPaidTowardsData(card, cardDebtSuggestion) ||
                                  "- -"}
                              </td> */}

                              <td className="ant-table-cell">
                                {card?.truelayer_card_id == null
                                  ? "Manual"
                                  : card?.is_token_expired ||
                                    card?.status === "deactive"
                                  ? "Expired"
                                  : "Connected"}
                              </td>
                            </tr>
                          ))}
                        {overdraft_account &&
                          overdraft_account.map((item, index) => (
                            <tr className="ant-table-row" key={index}>
                              <td className="ant-table-cell">
                                {accountIndex++}
                              </td>
                              <td
                                className="ant-table-cell custom-link underline-link"
                                onClick={() => this.displayAccountInfo(item)}
                              >
                                {item?.account_details?.provider
                                  ?.display_name ||
                                  item?.account_details?.display_name ||
                                  ""}{" "}
                                {(
                                  item?.account_details?.account_type || ""
                                ).replace("debit card", "overdraft")}
                              </td>
                              <td>
                                {!item.isLock ? (
                                  <img
                                    src={"/images/Redact.png"}
                                    alt="SuperFi logo"
                                    style={{ width: 100, height: 20 }}
                                  />
                                ) : (
                                  <div>
                                    {formatPrice(
                                      this.getPriceInfo(item),
                                      item?.account_details?.currency
                                    )}
                                  </div>
                                )}
                              </td>
                              <td className="ant-table-cell">
                                <div>
                                  <span
                                    onClick={(e) =>
                                      this.handleOverdraftBalanceLockClick(
                                        item?.user_overdraft_account_id
                                      )
                                    }
                                  >
                                    <img
                                      src={
                                        !item.isLock
                                          ? "/images/lock.png"
                                          : "/images/unlock.png"
                                      }
                                      alt="SuperFi logo"
                                      style={{ width: 16.66, height: 20 }}
                                    />
                                  </span>
                                </div>
                              </td>
                              <td className="ant-table-cell">Y</td>
                              <td className="ant-table-cell">N</td>
                              <td className="ant-table-cell">N</td>
                              <td className="ant-table-cell">N</td>
                              {/* <td className="ant-table-cell">
                                {getDebtSuggestionData(
                                  item,
                                  cardDebtSuggestion
                                ) || "- -"}
                              </td>
                              <td className="ant-table-cell">
                                {getPaidTowardsData(item, cardDebtSuggestion) ||
                                  "- -"}
                              </td> */}
                              <td className="ant-table-cell">
                                {item?.truelayer_account_id == null
                                  ? "Manual"
                                  : item?.is_token_expired === 1 ||
                                    item?.status === "deactive"
                                  ? "Expired"
                                  : "Connected"}
                              </td>
                            </tr>
                          ))}
                        {active_user?.klarna_account &&
                          active_user.klarna_account.map((item, index) => (
                            <tr className="ant-table-row" key={index}>
                              <td className="ant-table-cell">
                                {accountIndex++}
                              </td>
                              <td className="ant-table-cell">Klarna</td>
                              <td>
                                {!item.isLock ? (
                                  <img
                                    src={"/images/Redact.png"}
                                    alt="SuperFi logo"
                                    style={{ width: 100, height: 20 }}
                                  />
                                ) : (
                                  <div>
                                    {formatPrice(
                                      -this.getKlaranaPriceInfo(item)
                                    )}
                                  </div>
                                )}
                              </td>
                              <td className="ant-table-cell">
                                <div>
                                  <span
                                    onClick={(e) =>
                                      this.handleKlarnaBalanceLockClick(
                                        item?.klarna_id
                                      )
                                    }
                                  >
                                    <img
                                      src={
                                        !item.isLock
                                          ? "/images/lock.png"
                                          : "/images/unlock.png"
                                      }
                                      alt="SuperFi logo"
                                      style={{ width: 16.66, height: 20 }}
                                    />
                                  </span>
                                </div>
                              </td>
                              <td className="ant-table-cell">N</td>
                              <td className="ant-table-cell">N</td>
                              <td className="ant-table-cell">N</td>
                              <td className="ant-table-cell">Y</td>
                              {/* <td className="ant-table-cell">
                                {getDebtSuggestionData(
                                  item,
                                  klarnaDebtSuggestion
                                ) || "- -"}
                              </td>
                              <td className="ant-table-cell">
                                {getPaidTowardsData(
                                  item,
                                  klarnaDebtSuggestion
                                ) || "- -"}
                              </td> */}
                              <td className="ant-table-cell">Connected</td>
                            </tr>
                          ))}

                        {other_debts &&
                          other_debts.map((item, index) => (
                            <tr className="ant-table-row" key={index}>
                              <td className="ant-table-cell">
                                {accountIndex++}
                              </td>
                              <td className="ant-table-cell">
                                {item?.owe_debts + " - " + item?.debt_type}
                              </td>
                              <td>
                                {!item.isLock ? (
                                  <img
                                    src={"/images/Redact.png"}
                                    alt="SuperFi logo"
                                    style={{ width: 100, height: 20 }}
                                  />
                                ) : (
                                  <div>{formatPrice(item?.total_debt)}</div>
                                )}
                              </td>
                              <td className="ant-table-cell">
                                <div>
                                  <span
                                    onClick={(e) =>
                                      this.handleOtherDebtLockClick(
                                        item?.priority_non_priority_debts_id
                                      )
                                    }
                                  >
                                    <img
                                      src={
                                        !item.isLock
                                          ? "/images/lock.png"
                                          : "/images/unlock.png"
                                      }
                                      alt="SuperFi logo"
                                      style={{ width: 16.66, height: 20 }}
                                    />
                                  </span>
                                </div>
                              </td>
                              <td className="ant-table-cell">N</td>
                              <td className="ant-table-cell">Y</td>
                              <td className="ant-table-cell">N</td>
                              <td className="ant-table-cell">N</td>
                              <td className="ant-table-cell">- -</td>
                              <td className="ant-table-cell">- -</td>
                              <td className="ant-table-cell">Manual</td>
                            </tr>
                          ))}

                        {accountIndex === 1 && (
                          <tr className="ant-table-row">
                            <td colSpan={8}>No data found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>

              <Col md={24}>
                <h4>Bills</h4>
              </Col>

              <Col md={24}>
                <div className="ant-table-wrapper ">
                  <div className="ant-table">
                    <table>
                      <thead className="ant-table-thead">
                        <tr>
                          <th className="ant-table-cell">Bill Name</th>
                          <th className="ant-table-cell">Category</th>
                          <th className="ant-table-cell">Amount</th>
                          <th className="ant-table-cell">Status</th>
                          <th className="ant-table-cell">
                            Bill added by user?
                          </th>
                          <th className="ant-table-cell"> Due Date </th>
                        </tr>
                      </thead>
                      <tbody className="ant-table-tbody">
                        {processedUserBillList.length > 0 ? (
                          processedUserBillList.map((data, index) => (
                            <tr className="ant-table-row" key={index}>
                              <td
                                style={{
                                  color:
                                    data?.add_type == "Income"
                                      ? "blue"
                                      : "black",
                                }}
                                className="ant-table-cell"
                              >
                                {data.display_name || "--"}
                              </td>
                              <td
                                style={{
                                  color:
                                    data?.add_type == "Income"
                                      ? "blue"
                                      : "black",
                                }}
                                className="ant-table-cell"
                              >
                                {data?.truelayer_category?.[0] ||
                                  data?.add_type ||
                                  "General"}
                              </td>
                              <td className="ant-table-cell">
                                £ {data.amount || "--"}
                              </td>
                              <td className="ant-table-cell">{data.status}</td>
                              <td className="ant-table-cell">
                                {data?.bill_tracker_add_bill_id ? "Yes" : "No"}
                              </td>
                              <td className="ant-table-cell">
                                Actual :
                                {moment(data.actual_timestamp).format("LL")}
                                <br />
                                Predicted :{moment(data.timestamp).format("LL")}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr className="ant-table-row">
                            <td colSpan={5}>No data found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>

              <Col md={24}>
                <h4>Features</h4>
              </Col>
              <Col md={24}>
                <div className="ant-table-wrapper ">
                  <div className="ant-table">
                    <table>
                      <thead className="ant-table-thead">
                        <tr>
                          <th className="ant-table-cell">Onboarding</th>
                          <th className="ant-table-cell">Round up</th>
                          <th className="ant-table-cell">Swear Jar</th>
                          <th className="ant-table-cell">Bill Tracker</th>
                          <th className="ant-table-cell">Cashback click</th>
                          <th className="ant-table-cell">Switching click</th>
                          <th className="ant-table-cell">Support click</th>
                          <th className="ant-table-cell">Benefits click</th>
                          <th className="ant-table-cell">Reward tasks</th>
                        </tr>
                      </thead>
                      <tbody className="ant-table-tbody">
                        <tr className="ant-table-row">
                          {/* <td colSpan={8}>Coming Soon</td> */}
                          <td className="ant-table-cell">
                            {this.getUserStep()}
                          </td>
                          <td className="ant-table-cell">
                            {active_user?.info?.is_roundup == 1 ? "OFF" : "ON"}
                          </td>
                          <td className="ant-table-cell">
                            {active_user?.info?.is_swear_jar == 0
                              ? "OFF"
                              : "ON"}
                          </td>
                          <td className="ant-table-cell">
                            {active_user?.info?.total_bills_tracker
                              ? active_user?.info?.total_bills_tracker + " "
                              : 0 + " "}
                            Bills
                          </td>
                          <td className="ant-table-cell">
                            {active_user?.info?.cashback_click == 1 ? "Y" : "N"}
                          </td>
                          <td className="ant-table-cell">
                            {active_user?.info?.switching_click == 1
                              ? "Y"
                              : "N"}
                          </td>
                          <td className="ant-table-cell">
                            {active_user?.info?.support_click == 1 ? "Y" : "N"}
                          </td>
                          <td className="ant-table-cell">
                            {active_user?.info?.benefits_click == 1 ? "Y" : "N"}
                          </td>
                          <td className="ant-table-cell">{`${
                            active_user?.features?.tasks?.completed_tasks.toString() ||
                            "0"
                          }/${
                            active_user?.features?.tasks?.total_tasks.toString() ||
                            "0"
                          }`}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>

              <Col md={24}>
                <h4>Benefits and income</h4>
              </Col>
              <Col md={24}>
                <div className="ant-table-wrapper ">
                  <div className="ant-table">
                    <table>
                      <thead className="ant-table-thead">
                        <tr>
                          <th className="ant-table-cell">
                            Household Monthly Income
                          </th>
                          <th className="ant-table-cell">Household savings</th>
                          <th className="ant-table-cell">Recieves benefits</th>
                          <th className="ant-table-cell">
                            Total monthly benefits
                          </th>
                          <th className="ant-table-cell">
                            Defaulted last 12 months
                          </th>
                        </tr>
                      </thead>
                      <tbody className="ant-table-tbody">
                        <tr className="ant-table-row">
                          <td className="ant-table-cell">
                            {active_user?.userSocialData?.monthly_income_house
                              ? "£" +
                                active_user?.userSocialData
                                  ?.monthly_income_house
                              : "- -"}
                          </td>
                          <td className="ant-table-cell">
                            {active_user?.userSocialData?.house_savings
                              ? "£" + active_user?.userSocialData?.house_savings
                              : "- -"}
                          </td>
                          <td className="ant-table-cell">
                            {active_user?.userSocialData?.receiving_benefits
                              ? active_user?.userSocialData?.receiving_benefits
                                  .charAt(0)
                                  .toUpperCase()
                              : "- -"}
                          </td>
                          <td className="ant-table-cell">
                            {active_user?.userSocialData?.total_monthly_benefits
                              ? active_user?.userSocialData
                                  ?.total_monthly_benefits
                              : "- -"}
                          </td>
                          <td className="ant-table-cell">
                            {active_user?.userSocialData?.defaulted_bills
                              ? active_user?.userSocialData?.defaulted_bills
                                  .charAt(0)
                                  .toUpperCase()
                              : "- -"}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>

              <Col md={24}>
                <h4>Income and Expenditure</h4>
              </Col>
              <Col md={24}>
                <div className="ant-table-wrapper ">
                  <div className="ant-table">
                    <table>
                      <thead className="ant-table-thead">
                        <tr>
                          <th className="ant-table-cell">Completed</th>
                          <th className="ant-table-cell">Date completed</th>
                          <th className="ant-table-cell">Date Modified</th>
                          {/* <th className="ant-table-cell">Expenditure</th> */}
                          {active_user?.income_expense_form
                            ?.incomeExpenseFormCondition && (
                            <th className="ant-table-cell">Actions</th>
                          )}
                        </tr>
                      </thead>
                      <tbody className="ant-table-tbody">
                        <tr className="ant-table-row">
                          <td className="ant-table-cell">
                            {active_user?.income_expense_form
                              ?.incomeExpenseFormCondition
                              ? "Yes"
                              : "No"}
                          </td>
                          <td className="ant-table-cell">
                            {active_user?.income_expense_form
                              ?.incomeExpenseFormCondition
                              ? active_user?.income_expense_form
                                  ?.incomeExpenseFormInfo?.creationTime
                              : "- -"}
                          </td>
                          <td className="ant-table-cell">
                            {active_user?.income_expense_form
                              ?.incomeExpenseFormCondition
                              ? active_user?.income_expense_form
                                  ?.incomeExpenseFormInfo?.lastModifiedTime
                              : "- -"}
                          </td>
                          {/* <td className="ant-table-cell">1500</td>
                          <td className="ant-table-cell">2500</td> */}
                          {active_user?.income_expense_form
                            ?.incomeExpenseFormCondition && (
                            <td className="ant-table-cell">
                              <div className="btn-actions">
                                <span
                                  style={{
                                    textDecoration: "underline",
                                  }}
                                  onClick={(e) =>
                                    this.handleDownloadCSV(
                                      active_user?.income_expense_form
                                    )
                                  }
                                >
                                  Download
                                </span>
                              </div>
                            </td>
                          )}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>

              <Col md={24}>
                <h4>Wallets</h4>
              </Col>
              <Col md={24}>
                <div className="ant-table-wrapper ">
                  <div className="ant-table">
                    <table>
                      <thead className="ant-table-thead">
                        <tr>
                          <th className="ant-table-cell">Cashback Balance</th>
                          <th className="ant-table-cell">Coin Balance</th>
                          <th className="ant-table-cell">Actions</th>
                        </tr>
                      </thead>
                      <tbody className="ant-table-tbody">
                        <tr className="ant-table-row">
                          <td className="ant-table-cell">
                            {active_user?.info?.user_cashback_balance
                              ? "£" + active_user?.info?.user_cashback_balance
                              : "£0.00"}
                          </td>
                          <td
                            className="ant-table-cell"
                            style={{ width: "70%" }}
                          >
                            {active_user?.info?.user_coin_balance
                              ? active_user?.info?.user_coin_balance
                              : 0}
                          </td>
                          <td className="ant-table-cell">
                            <div className="btn-actions">
                              <span
                                style={{
                                  marginRight: 16,
                                }}
                                onClick={(e) => this.handleEditBalanceModal()}
                              >
                                Edit
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>

              <Col md={24}>
                <h4>Comments</h4>
              </Col>
              <Col md={24}>
                <div className="ant-table-wrapper ">
                  <div className="ant-table">
                    <table>
                      <thead className="ant-table-thead">
                        <tr>
                          <th
                            className="ant-table-cell"
                            style={{ width: "80%" }}
                          >
                            Comment
                          </th>
                          <th className="ant-table-cell">Actions</th>
                        </tr>
                      </thead>
                      <tbody className="ant-table-tbody">
                        <tr className="ant-table-row">
                          <td
                            className="ant-table-cell"
                            style={{ textAlign: "justify" }}
                          >
                            {active_user?.info?.user_comment || "- -"}
                          </td>

                          <td
                            className="ant-table-cell"
                            style={{ display: "flex", border: "none" }}
                          >
                            <div className="btn-actions">
                              <span onClick={(e) => this.handleEditComment()}>
                                Edit
                              </span>
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>

              <Col md={24}>
                <h4>Favorite brands information</h4>
              </Col>
              <Col md={24}>
                <div className="ant-table-wrapper ">
                  <div className="ant-table">
                    <table>
                      <thead className="ant-table-thead">
                        <tr>
                          <th
                            className="ant-table-cell"
                            style={{ width: "80%" }}
                          >
                            Data
                          </th>
                        </tr>
                      </thead>
                      <tbody className="ant-table-tbody">
                        <tr className="ant-table-row">
                          <td
                            className="ant-table-cell"
                            style={{ textAlign: "justify" }}
                          >
                            <pre>{JSON.stringify(favBrandsData, null, 2)}</pre>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </Col>
            </Row>
          </>
        )}

        <CustomModal
          className={"signup-setting-modal"}
          visible={accountInfoModal}
          onClose={this.displayAccountInfo}
        >
          {selectedAccount && selectedAccount?.account_details && (
            <>
              <p className="txt-title">
                {selectedAccount?.account_details?.provider?.display_name ||
                  selectedAccount?.account_details?.display_name ||
                  ""}{" "}
                {(selectedAccount?.account_details?.account_type || "").replace(
                  "debit card",
                  "overdraft"
                )}{" "}
                account
              </p>

              <div className="txt-info">
                Sort code
                <br />
                {this.getSortCode(selectedAccount)}
                <hr />
                Account Number
                <br />
                {this.getAccountNumber(selectedAccount)}
              </div>
            </>
          )}
          {selectedAccount?.card_details && (
            <>
              <p className="txt-title">
                {selectedAccount?.card_details?.provider?.display_name ||
                  selectedAccount?.card_details?.display_name ||
                  ""}{" "}
                {selectedAccount?.card_details?.card_network || ""}
              </p>

              <div className="txt-info">
                Card Number
                <br />
                {(selectedAccount?.card_details?.partial_card_number ||
                  selectedAccount?.card_details?.card_number) === undefined
                  ? ""
                  : `XXXX ${trimAccountNumber(
                      selectedAccount?.card_details?.partial_card_number ||
                        selectedAccount?.card_details?.card_number,
                      5
                    )}`}
                <hr />
                PAN Number
                <br />
                {this.getPanNumber(selectedAccount?.card_details?.pan_number)}
                {/* {selectedAccount?.card_details?.pan_number === undefined
                  ? ""
                  : `XXXX ${trimAccountNumber(
                      selectedAccount?.card_details?.pan_number,
                      5
                    )}`} */}
              </div>
            </>
          )}
        </CustomModal>

        <CustomModal
          className={"signup-setting-modal"}
          visible={pauseUserModal}
          onClose={this.handlePauseModal}
        >
          <p className="txt-title">{userAction} this user</p>
          {userAction === "Pause" ? (
            <p className="txt-info">
              They will not be able to sign into their SuperFi account
            </p>
          ) : (
            <p className="txt-info">
              They will be able to sign into their SuperFi account
            </p>
          )}

          <Button
            type="primary"
            block
            onClick={() => this.submitUserAction(userAction)}
          >
            {userAction}
          </Button>
        </CustomModal>

        <CustomModal
          className={"signup-setting-modal"}
          visible={deleteUserModal}
          onClose={this.handleDeleteModal}
        >
          <p className="txt-title">Delete this user?</p>
          <p className="txt-info">
            This change cannot be undone. All their content will be removed from
            SuperFi
          </p>

          <Button
            type="primary"
            block
            onClick={() => this.submitUserAction("delete")}
          >
            Delete
          </Button>
        </CustomModal>

        <CustomModal
          className={"signup-setting-modal"}
          visible={editBalanceModal}
          onClose={this.handleEditBalanceModal}
        >
          <Form
            size="large"
            initialValues={active_user?.info}
            onFinish={(val) => this.submitForm(val)}
            layout="vertical"
            className="form-login"
          >
            <p className="txt-title">Edit cashback and coin balance</p>
            <div className="custom-form-container">
              <Form.Item
                name="user_cashback_balance"
                label="Cashback"
                rules={[
                  {
                    required: true,
                    message: "Please enter cashback balance",
                  },
                ]}
              >
                <Input
                  placeholder="Cashback balance"
                  type="text"
                  value={active_user?.info?.user_cashback_balance || ""}
                />
              </Form.Item>

              <Form.Item
                name="user_coin_balance"
                label="Coins"
                rules={[
                  {
                    required: true,
                    message: "Please enter coin balance",
                  },
                ]}
              >
                <Input
                  placeholder="Coin balance"
                  type="text"
                  value={active_user?.info?.user_coin_balance || ""}
                />
              </Form.Item>
            </div>

            <Button
              className="text-gray"
              htmlType="submit"
              type="primary"
              block
            >
              Save
            </Button>
          </Form>
        </CustomModal>

        <CustomModal
          className={"signup-setting-modal"}
          visible={editCommentModal}
          onClose={this.handleEditComment}
        >
          <Form
            size="large"
            initialValues={active_user?.info}
            onFinish={(val) => this.submitComment(val)}
            layout="vertical"
            className="form-login"
          >
            <p className="txt-title">Add a Comment</p>
            <div className="custom-form-container">
              <Form.Item
                name="user_comment"
                label="Comment"
                rules={[
                  {
                    required: false,
                    message: "Please enter comment",
                  },
                ]}
              >
                <TextArea
                  placeholder="Comment"
                  type="text"
                  value={active_user?.info?.user_comment || "--"}
                  style={{ height: "120px" }}
                />
              </Form.Item>
            </div>

            <Button
              className="text-gray"
              htmlType="submit"
              type="primary"
              block
            >
              Save
            </Button>
          </Form>
        </CustomModal>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    active_user: state.active_user,
    partnerOnboardingCodes: state.partnerOnboardingCodes,
    admin: state.adminInfo,
    // userBillList: state.userBillList,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(details));
