import { Button, Card, Col, Row, Form, Input } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import CustomModal from "../../components/CustomModal";
import BasicLayout from "../../components/layout/index";
import { notifyError } from "../../common/notification";
import { formatPrice } from "../../common/utils";
import "./index.less";

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      pauseSignupModal: false,
      cashbackModal: false,
      amountValue: "",
    };
  }

  async componentDidMount() {
    await this.props.loadAdminDashboardStats();
    await this.props.getMarketplaceClickInfo();
    await this.props.loadMoneyplusDueData();
    await this.props.loadUserDashboardMiscData();
  }

  // Open the pause/unpause pop up
  handleSignupModal = () => {
    const { dashboard } = this.props;
    const { pauseSignupModal } = this.state;
    if (!dashboard.isSignupPause) {
      this.setState({ pauseSignupModal: !pauseSignupModal });
    } else {
      this.handleSignupSetting();
    }
  };

  // Open cashback pop up
  // handleCashbackModal = () => {
  //   const { cashbackModal } = this.state;
  //   this.setState({ cashbackModal: !cashbackModal });
  // };

  handleMarkeplaceClicksModal = () => {
    const { cashbackModal } = this.state;
    this.setState({ cashbackModal: !cashbackModal });
  };

  // Save the cashback amount for the month
  // submitForm = (values) => {
  //   if (isNaN(values.amount)) {
  //     notifyError("Amount must be a number");
  //     return;
  //   }

  //   var data = { amount: values.amount };

  //   this.props.saveCashbackAmount(data).then(() => {
  //     this.props.loadAdminDashboardStats();
  //     this.setState({ cashbackModal: false });
  //   });
  // };

  // Save the pause/unpause data
  handleSignupSetting = () => {
    const { dashboard } = this.props;
    var data = { action: dashboard?.isSignupPause ? "unpause" : "pause" };

    this.props.saveNewSignupSettting(data).then(() => {
      this.props.loadAdminDashboardStats();
      this.setState({ pauseSignupModal: false });
    });
  };

  render() {
    const { pauseSignupModal, cashbackModal } = this.state;
    const {
      dashboard,
      marketplaceClickInfo,
      moneyplusDueData,
      userMiscDataDashoard,
    } = this.props;

    // console.log("userMiscDataDashoard ==>", userMiscDataDashoard);

    let totalMarketplaceClicks = 0;
    if (marketplaceClickInfo.length > 0) {
      marketplaceClickInfo.map((data) => {
        totalMarketplaceClicks =
          totalMarketplaceClicks + Number(data.total_clicks);
      });
    }

    return (
      <BasicLayout className="page-dashboard">
        <h2 className="page-title">Your Dashboard</h2>
        <div className="page-content">
          <Row gutter={[15, 20]}>
            <Col xs={24} md={12} lg={6}>
              <Card title={"Total\ndownloads"} className="custom-card">
                <h4>{dashboard?.totalDownloads || 0}</h4>
              </Card>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Card title="Total sign ups" className="custom-card">
                <h4>{dashboard?.totalSignups || 0}</h4>
              </Card>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Card title="Total users" className="custom-card">
                <h4>{dashboard?.totalUsers || 0}</h4>
              </Card>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Card title="Total customer debt" className="custom-card">
                <h4 className="small-amount">
                  {formatPrice(dashboard?.totalCustomerDebts || 0)}
                </h4>
              </Card>
            </Col>
          </Row>
          <Row gutter={[15, 20]}>
            <Col xs={24} md={12} lg={6}>
              <Card title="No. active accounts total" className="custom-card">
                <h4>{dashboard?.totalActiveAccount || 0}</h4>
              </Card>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Card
                title={"No. users active\nlast 30 days"}
                className="custom-card"
              >
                <h4>{dashboard?.totalActiveAccount30Days || 0}</h4>
              </Card>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Card title={"New users last\n30 days"} className="custom-card">
                <h4>{dashboard?.totalNewUser30Days || 0}</h4>
              </Card>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Card title="Total interest saved" className="custom-card">
                <h4 className="small-amount">
                  {formatPrice(dashboard?.totalInterestSaved || 0)}
                </h4>
              </Card>
            </Col>
          </Row>
          <Row gutter={[15, 20]}>
            {/* <Col xs={24} md={12} lg={6}>
              <Card
                title={`${dashboard?.NoOfActiveTasks}/${
                  dashboard?.NoOfActiveTasks
                } Tasks completed${"\n"}(This month)`}
                className="custom-card"
                onClick={() => {
                  localStorage.setItem(
                    "NoOfActiveTasks",
                    dashboard?.NoOfActiveTasks
                  );
                  const { history } = this.props;
                  history.push(`/admin/dashboard/completetask`);
                }}
              >
                <h4>{dashboard?.totalUserTaskComplete || 0}</h4>
              </Card>
            </Col> */}
            <Col xs={24} md={12} lg={6}>
              <Card title="No. recommend SuperFi" className="custom-card">
                <Row>
                  <Col md={12}>
                    <span className="emoji" role="img" aria-label="Dislike">
                      👎
                    </span>
                    <h4 className="small">
                      {Math.round(dashboard?.totalRecommededDown || 0)}%
                      {"  (" + dashboard["totalRecommededDownCount"] + ")"}
                    </h4>
                  </Col>
                  <Col md={12}>
                    <span className="emoji" role="img" aria-label="Like">
                      👍
                    </span>
                    <h4 className="small">
                      {Math.round(dashboard?.totalRecommededUp || 0)}%
                      {"  (" + dashboard["totalRecommededUpCount"] + ")"}
                    </h4>
                  </Col>
                </Row>
              </Card>
            </Col>
            {/* <Col xs={24} md={12} lg={6}>
              <Card
                title={"Total cashback\n(this month)"}
                className="custom-card"
                onClick={this.handleCashbackModal}
              >
                <h4>£{dashboard?.totalCashback || 0}</h4>
              </Card>
            </Col> */}
            <Col xs={24} md={12} lg={6}>
              <Card
                title={"Marketplace\n clicks"}
                className="custom-card"
                onClick={() => {
                  const { history } = this.props;
                  history.push(`/admin/dashboard/marketplaceClicks`);
                }}
              >
                <h4>{totalMarketplaceClicks || 0}</h4>
              </Card>
            </Col>

            <Col xs={24} md={12} lg={6}>
              <Card
                title={"No. sessions in last 30 days"}
                className="custom-card"
              >
                <h4>{dashboard?.totalSessionsLast30Days || 0}</h4>
              </Card>
            </Col>

            <Col xs={24} md={12} lg={6}>
              <Card title={"Average session length"} className="custom-card">
                <h4>{dashboard?.averageSessionLength || "00:00"}</h4>
              </Card>
            </Col>

            <Col xs={24} md={12} lg={6}>
              <Card
                title={"Vulnerable customers"}
                className="custom-card"
                onClick={() => {
                  const { history } = this.props;
                  history.push({
                    pathname: `/admin/users`,
                    state: { type: "vulnerable" },
                  });
                }}
              >
                <h4>{dashboard?.totalVulnerableCustomers || "0"}</h4>
              </Card>
            </Col>

            <Col xs={24} md={12} lg={6}>
              <Card
                title={"Users with connected accounts"}
                className="custom-card"
                onClick={() => {
                  const { history } = this.props;
                  history.push({
                    pathname: `/admin/users`,
                    state: {
                      type: "connected_accounts",
                      data: dashboard?.uniqueConnectedUsersID,
                    },
                  });
                }}
              >
                <h4>{dashboard?.uniqueConnectedUsers || "0"}</h4>
              </Card>
            </Col>

            <Col xs={24} md={12} lg={6}>
              <Card
                title={"Users with connected accounts (average no of accounts)"}
                className="custom-card"
              >
                <h4>{dashboard?.totalConnectedAccounts || "0"}</h4>
              </Card>
            </Col>

            <Col xs={24} md={12} lg={6}>
              <Card
                title={"Total Social Tariff checks"}
                className="custom-card"
              >
                <h4>{dashboard?.socialTariffChecks || "0"}</h4>
              </Card>
            </Col>

            <Col xs={24} md={12} lg={6}>
              <Card
                title={"Social Tariff identified savings"}
                className="custom-card"
              >
                <h4>
                  {"£" +
                    (dashboard?.socialTariffChecksEligible
                      ? dashboard?.socialTariffChecksEligible * 540
                      : "0")}
                </h4>
              </Card>
            </Col>
            <Col xs={24} md={12} lg={6}>
              <Card
                title={"Upcoming Moneyplus payments"}
                className="custom-card"
                onClick={() => {
                  const { history } = this.props;
                  history.push(`/admin/moneyplus-users`);
                }}
              >
                <h4>{moneyplusDueData.length || "NA"}</h4>
              </Card>
            </Col>

            <Col xs={24} md={12} lg={6}>
              <Card
                title={"Unique Users Login Today."}
                className="custom-card"
                onClick={() => {
                  const { history } = this.props;
                  history.push({
                    pathname: `/admin/users`,
                    state: {
                      type: "connected_accounts",
                      data: dashboard?.uniqueTodayLoggedUsers.map((data) => {
                        return data.user_id;
                      }),
                    },
                  });
                }}
              >
                <h4>{dashboard?.uniqueTodayLoggedUsers?.length || "0"}</h4>
              </Card>
            </Col>

            <Col xs={24} md={12} lg={6}>
              <Card
                title={"Users stuck on Onboarding."}
                className="custom-card"
                onClick={() => {
                  const { history } = this.props;
                  history.push({
                    pathname: `/admin/users`,
                    state: {
                      type: "connected_accounts",
                      data: dashboard?.uniqueOnboardingStuckUsers.map(
                        (data) => {
                          return data.user_id;
                        }
                      ),
                    },
                  });
                }}
              >
                <h4>{dashboard?.uniqueOnboardingStuckUsers?.length || "0"}</h4>
              </Card>
            </Col>

            <Col xs={24} md={12} lg={6}>
              <Card
                title={"Overdue Bill Users"}
                className="custom-card"
                onClick={() => {
                  const { history } = this.props;
                  history.push({
                    pathname: `/admin/overdue-data`,
                    state: {
                      data: userMiscDataDashoard?.passOverdueData,
                    },
                  });
                }}
              >
                <h4>{userMiscDataDashoard?.passOverdueData?.length || "0"}</h4>
              </Card>
            </Col>

            <Col xs={24} md={12} lg={6}>
              <Card
                title={"Unlikely to afford Bill Users"}
                className="custom-card"
                onClick={() => {
                  const { history } = this.props;
                  history.push({
                    pathname: `/admin/unlikely-afford-data`,
                    state: {
                      data: userMiscDataDashoard?.passUnlikelyData,
                    },
                  });
                }}
              >
                <h4>{userMiscDataDashoard?.passUnlikelyData?.length || "0"}</h4>
              </Card>
            </Col>

            <Col xs={24} md={12} lg={6}>
              <Card
                title={"Brand Boosted Users"}
                className="custom-card"
                onClick={() => {
                  const { history } = this.props;
                  history.push({
                    pathname: `/admin/boosted-users`,
                    state: {
                      data: dashboard?.finalBoosterData || [],
                    },
                  });
                }}
              >
                <h4>{dashboard?.finalBoosterData?.length || "0"}</h4>
              </Card>
            </Col>

            <Col xs={24} md={12} lg={6}>
              <Card
                title={"Potential CT Transactions"}
                className="custom-card"
                onClick={() => {
                  const { history } = this.props;
                  history.push({
                    pathname: `/admin/potential-ct`,
                    state: {
                      data: userMiscDataDashoard?.potentialCouncilData,
                    },
                  });
                }}
              >
                <h4>
                  {userMiscDataDashoard?.potentialCouncilData?.length || "0"}
                </h4>
              </Card>
            </Col>

            <Col xs={24} md={12} lg={6}>
              <Card
                title={"Potential Income Transactions"}
                className="custom-card"
                onClick={() => {
                  const { history } = this.props;
                  history.push({
                    pathname: `/admin/potential-income`,
                    state: {
                      data: userMiscDataDashoard?.potentialIncomeData,
                    },
                  });
                }}
              >
                <h4>
                  {userMiscDataDashoard?.potentialIncomeData?.length || "0"}
                </h4>
              </Card>
            </Col>
          </Row>

          <Button type="primary" block onClick={this.handleSignupModal}>
            {dashboard?.isSignupPause ? "Unpause" : "Pause"} sign-ups
          </Button>
        </div>

        <CustomModal
          className={"signup-setting-modal"}
          visible={pauseSignupModal}
          onClose={this.handleSignupModal}
        >
          <p className="txt-title">Pause all new sign ups?</p>
          <p className="txt-info">
            This will stop new users creating accounts with SuperFi
          </p>

          <Button type="primary" block onClick={this.handleSignupSetting}>
            {dashboard?.isSignupPause ? "Unpause" : "Pause"} sign-ups
          </Button>
        </CustomModal>

        {/* <CustomModal
          className={"signup-setting-modal"}
          visible={cashbackModal}
          onClose={this.handleCashbackModal}
        >
          <Form
            size="large"
            initialValues={{ amount: dashboard?.totalCashback || 0 }}
            onFinish={(val) => this.submitForm(val)}
            layout="vertical"
            className="form-login"
          >
            <p className="txt-title">Total cashback</p>
            <p className="txt-info">
              SuperFi to input the amount of cashback on offer this month
            </p>

            <div className="custom-form-container">
              <Form.Item
                name="amount"
                label="Amount"
                rules={[
                  {
                    required: true,
                    message: "Please enter amount",
                  },
                ]}
              >
                <Input addonBefore="£" placeholder="0" type="text" />
              </Form.Item>
            </div>

            <Button
              className="text-gray"
              htmlType="submit"
              type="primary"
              block
            >
              Save amount
            </Button>
          </Form>
        </CustomModal> */}
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    admin: state.adminInfo,
    dashboard: state.dashboard,
    rewards: state.rewards,
    marketplaceClickInfo: state.marketplaceClickInfo,
    moneyplusDueData: state.moneyplusDueData,
    userMiscDataDashoard: state.userMiscDataDashoard,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(Dashboard));
