import { Col, Row, Table } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";
import CustomModal from "../../components/CustomModal";
import { notifyError, notifySuccess } from "../../common/notification";
let accessLock = false;

class index extends Component {
  state = {
    statusData: [],
    statusModal: false,
  };
  componentDidMount() {
    this.props.loadUniqueLoginHistory();
    if (this?.props?.admin?.access_lock == 1) {
      accessLock = true;
    }
  }
  componentWillReceiveProps() {
    if (this?.props?.admin?.access_lock == 1) {
      accessLock = true;
    }
  }

  handleStatusModal = (data) => {
    if (accessLock) {
      notifyError("You do not have permission to access this.");
      return false;
    }
    const { statusModal } = this.state;
    this.setState({
      statusData: data ? data : {},
      statusModal: !statusModal,
    });
  };
  // Render the card info in table formate
  columns = [
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      render: (_text, record) => (
        <p
          style={{
            width: 300,
          }}
        >
          {record.date || ""}
        </p>
      ),
    },
    {
      title: "User Count",
      dataIndex: "users_number",
      key: "users_number",
      render: (_text, record) => <p>{record.users_number || ""}</p>,
    },
    {
      title: "Signups Today",
      dataIndex: "signups_number",
      key: "signups_number",
      render: (_text, record) => <p>{record?.signups_number || "NA"}</p>,
    },
    {
      title: "Existing Users",
      dataIndex: "existing_number",
      key: "existing_number",
      render: (_text, record) => <p>{record?.existing_number || "NA"}</p>,
    },
    {
      title: "Actions",
      key: "action",
      render: (_text, record) => {
        return (
          <div className="btn-actions">
            <span
              onClick={() => {
                this.handleStatusModal(record?.users_data || "");
              }}
            >
              View User Info
            </span>
          </div>
        );
      },
    },
  ];
  columnsUserList = [
    {
      title: "ID",
      dataIndex: "user_id", // Corresponds to the key in the data object
      key: "user_id",
    },
    {
      title: "Email",
      dataIndex: "u_email_id",
      key: "u_email_id",
    },
  ];

  render() {
    const { uniqueLoginHistory } = this.props;
    const { statusModal } = this.state;
    let results = [];
    if (uniqueLoginHistory && uniqueLoginHistory.length > 0) {
      for (var i = 0; i < uniqueLoginHistory.length; i++) {
        var key = `unique-login-data-${uniqueLoginHistory[i].admin_unique_login_data_id}`;

        results.push({
          sr_no: i + 1,
          key: key,
          ...uniqueLoginHistory[i],
        });
      }
    }

    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>Unique User Login History!</h4>
          </Col>
        </Row>
        <Row className="card-table">
          <Table
            dataSource={results}
            columns={this.columns}
            sortOrder={false}
            pagination={false}
          />
        </Row>
        <CustomModal
          className={"signup-setting-modal"}
          visible={statusModal}
          onClose={this.handleStatusModal}
        >
          {
            <Table
              dataSource={this.state.statusData}
              columns={this.columnsUserList}
            />
          }
        </CustomModal>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    uniqueLoginHistory: state.uniqueLoginHistory,
    admin: state.adminInfo,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
