import { Col, Row, Table, Button } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import "./index.less";
import moment from "moment";
let accessLock = false;

// It's shows the card catalog info
class index extends Component {
  state = {
    boostedData: [],
  };
  async componentDidMount() {
    if (this?.props?.admin?.access_lock == 1) {
      accessLock = true;
    }
    if (this?.props?.location?.state?.data) {
      this.setState({ boostedData: this?.props?.location?.state?.data });
    } else {
      await this.props.loadAdminDashboardStats();
      this.setState({
        boostedData: this?.props?.dashboard?.finalBoosterData || [],
      });
    }
  }

  componentWillReceiveProps() {
    if (this?.props?.admin?.access_lock == 1) {
      accessLock = true;
    }
  }

  // Render the card info in table formate
  columns = [
    {
      title: "User ID",
      dataIndex: "_user_id",
      key: "_user_id",
      render: (_text, record) => (
        <p style={{ width: "300px" }}>{record._user_id || ""}</p>
      ),
    },
    {
      title: "User Email",
      dataIndex: "u_email_id",
      key: "u_email_id",
      render: (_text, record) => (
        <p style={{ width: "300px" }}>
          {accessLock ? "**********" : record.u_email_id}
        </p>
      ),
    },
    {
      title: "Date created",
      dataIndex: "date_created",
      key: "date_created",
      render: (_text, record) => (
        <p style={{ width: "300px" }}>{record.date_created || ""}</p>
      ),
    },

    {
      title: "Boosted Brands",
      dataIndex: "boosted_brands",
      key: "boosted_brands",
      render: (_text, record) => (
        <p style={{ width: "300px" }}>
          {JSON.stringify(record?.boosted_brands) || " - - -"}
        </p>
      ),
    },

    // {
    //   title: "Actions",
    //   key: "action",
    //   render: (_text, record) => {
    //     return (
    //       <div className="btn-actions" style={{ width: "250px" }}>
    //         <span
    //           style={{
    //             marginRight: 16,
    //           }}
    //           onClick={() => {
    //             this.handleStatusModal(record, "paid");
    //           }}
    //         >
    //           Mark as Paid
    //         </span>
    //         <span
    //           onClick={() => {
    //             this.handleStatusModal(record, "delete");
    //           }}
    //         >
    //           Delete Bill
    //         </span>
    //       </div>
    //     );
    //   },
    // },
  ];

  render() {
    const { boostedData } = this.state;
    let results = [];
    if (boostedData && boostedData.length > 0) {
      for (var i = 0; i < boostedData.length; i++) {
        var key = `user-boosted-data-${i}-index-boost`;

        results.push({
          sr_no: i + 1,
          key: key,
          ...boostedData[i],
        });
      }
    }

    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>Boosted Users! ( {boostedData.length} )</h4>
          </Col>
        </Row>
        <Row>
          <Table
            dataSource={results}
            columns={this.columns}
            sortOrder={false}
            pagination={false}
          />
        </Row>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    dashboard: state.dashboard,
    admin: state.adminInfo,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
