import { Button, Col, Row, Form, Input, Table, Select } from "antd";
import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";
import * as actions from "../../actions";
import BasicLayout from "../../components/layout/index";
import CustomModal from "../../components/CustomModal";
import { notifyError, notifySuccess } from "../../common/notification";
import "./index.less";
const { Search } = Input;
let accessLock = false;

// It's shows the card catalog info
class index extends Component {
  state = {
    editIconModal: false,
    addMerchantModal: false,
    selectedMerchantData: null,
    deleteModal: false,
    deleteId: null,
    updateId: null,
  };

  componentDidMount() {
    if (this?.props?.admin?.access_lock == 1) {
      accessLock = true;
    }
    // this.props.loadCardCatalog();
    this.props.loadMerchantListing(null);
    this.props.loadTags();
  }

  componentWillReceiveProps() {
    if (this?.props?.admin?.access_lock == 1) {
      accessLock = true;
    }
  }

  // Open the edit card pop up
  handleEditIconModal = (data, evt) => {
    if (accessLock) {
      notifyError("You do not have permission for this action.");
      return false;
    }
    const { editIconModal } = this.state;
    this.setState({
      editIconModal: !editIconModal,
      selectedMerchantData: data,
      updateId: data?.bill_and_utility_merchant_list_id,
    });
  };
  deleteActionSubmit = () => {
    this.props
      .deleteMerchantListingData({ id: this.state.deleteId })
      .then((res) => {
        if (res && res.data && res.data.status) {
          this.setState({
            deleteModal: false,
            deleteId: null,
          });
          notifySuccess(res?.data?.message || "Done");
          this.props.loadMerchantListing(null);
        } else {
          var errorMessage =
            res?.data?.message || res?.err?.message || "Something went wrong";
          notifyError(errorMessage);
        }
      });
  };

  // Add, Edit and Delete Icons
  submitForm = (values) => {
    let passObj = this.state.editIconModal
      ? { ...values, id: this.state.updateId }
      : values;
    this.props.addMerchantListing(passObj).then((res) => {
      if (res && res.data && res.data.status) {
        this.setState({
          addMerchantModal: false,
          editIconModal: false,
        });
        notifySuccess(res?.data?.message || "Done");
        this.props.loadMerchantListing(null);
      } else {
        var errorMessage =
          res?.data?.message || res?.err?.message || "Something went wrong";
        notifyError(errorMessage);
      }
    });
  };

  handleAddModal = () => {
    if (accessLock) {
      notifyError("You do not have permission for this action.");
      return false;
    }
    const { addMerchantModal } = this.state;
    this.setState({
      addMerchantModal: !addMerchantModal,
      selectedMerchantData: null,
    });
  };

  handleCloseModal = () => {
    this.setState({
      addMerchantModal: false,
      editIconModal: false,
      selectedMerchantData: null,
    });
  };

  handleDeleteModal = (id) => {
    if (accessLock) {
      notifyError("You do not have permission for this action.");
      return false;
    }
    const { deleteModal } = this.state;
    if (id) {
      this.setState({
        deleteModal: !deleteModal,
        deleteId: id,
      });
    } else {
      this.setState({
        deleteModal: !deleteModal,
        deleteId: null,
      });
    }
  };

  onSearch = (value) => {
    this.props.loadMerchantListing(value);
  };

  handleDropdownItemClick = (e) => {};

  // Render the card info in table formate
  columns = [
    {
      title: "ID",
      dataIndex: "bill_and_utility_merchant_list_id",
      key: "bill_and_utility_merchant_list_id",
      render: (_text, record) => (
        <p className="brand-name">
          {record.bill_and_utility_merchant_list_id || ""}
        </p>
      ),
    },
    {
      title: "Bill/Utility name",
      dataIndex: "bill_utility_name",
      key: "bill_utility_name",
      render: (_text, record) => (
        <p className="brand-name">{record.bill_utility_name || ""}</p>
      ),
    },
    {
      title: "Tag name",
      dataIndex: "name",
      key: "name",
      render: (_text, record) => (
        <p className="brand-name">{record.name || ""}</p>
      ),
    },
    {
      title: "Phone number",
      dataIndex: "contact_phone_number",
      key: "contact_phone_number",
      render: (_text, record) => (
        <p className="brand-name">{record.contact_phone_number || ""}</p>
      ),
    },
    {
      title: "Email",
      dataIndex: "contact_email",
      key: "contact_email",
      render: (_text, record) => (
        <p className="brand-name">{record.contact_email || ""}</p>
      ),
    },
    {
      title: "Website",
      dataIndex: "website",
      key: "website",
      render: (_text, record) => (
        <p className="brand-name">{record.website || ""}</p>
      ),
    },
    {
      title: "Cancel instruction",
      dataIndex: "cancel_instruction",
      key: "cancel_instruction",
      render: (_text, record) => (
        <p className="brand-name">{record.cancel_instruction || ""}</p>
      ),
    },

    {
      title: "Actions",
      key: "action",
      render: (_text, record) => {
        return (
          <div className="btn-actions">
            <span
              style={{
                marginRight: 16,
              }}
              onClick={(e) => {
                this.handleDeleteModal(
                  record.bill_and_utility_merchant_list_id
                );
              }}
            >
              Delete
            </span>
            <span onClick={(e) => this.handleEditIconModal(record, e)}>
              Edit
            </span>
          </div>
        );
      },
    },
  ];

  render() {
    const { merchantListingInfo } = this.props;
    const {
      editIconModal,
      selectedMerchantData,
      addMerchantModal,
      deleteModal,
    } = this.state;
    let tagInfo = this?.props?.tagInfo || [];
    let results = [];
    if (merchantListingInfo && merchantListingInfo.length > 0) {
      for (var i = 0; i < merchantListingInfo.length; i++) {
        var key = `merchant-listing-${merchantListingInfo[i].bill_and_utility_merchant_list_id}`;
        results.push({
          sr_no: i + 1,
          key: key,
          ...merchantListingInfo[i],
        });
      }
    }

    return (
      <BasicLayout>
        <Row className="section-title">
          <Col md={24}>
            <h4>Merchant Listing</h4>
            <Search
              placeholder="Search..."
              onSearch={this.onSearch}
              style={{
                width: 400,
                paddingLeft: 10,
              }}
              enterButton
              size="large"
            />
          </Col>
          <Col md={23}>
            <div>
              <Button
                className="btn-add-card"
                type="primary"
                onClick={this.handleAddModal}
              >
                Add new merchant
              </Button>
            </div>
          </Col>
        </Row>
        <Row>
          <Table
            dataSource={results}
            columns={this.columns}
            sortOrder={false}
            pagination={false}
          />
        </Row>

        {/* <div>
          <Button
            className="btn-add-card"
            type="primary"
            onClick={() => this.handleAddCardModal(true)}
          >
            Add new card
          </Button>
        </div> */}

        {(addMerchantModal || (editIconModal && selectedMerchantData)) && (
          <CustomModal
            className={"signup-setting-modal"}
            visible={addMerchantModal || editIconModal}
            onClose={this.handleCloseModal}
          >
            <Form
              size="large"
              initialValues={selectedMerchantData}
              onFinish={(val) => this.submitForm(val)}
              layout="vertical"
              className="form-login"
            >
              <p className="txt-title">Edit a merchant</p>
              <div className="custom-form-container">
                <Form.Item
                  name="bill_utility_name"
                  label="Name"
                  rules={[
                    {
                      required: true,
                      message: "Please enter merchant name",
                    },
                  ]}
                >
                  <Input
                    placeholder="Merchant name"
                    type="text"
                    value={selectedMerchantData?.bill_utility_name || ""}
                  />
                </Form.Item>

                <Form.Item
                  name="tag"
                  label="Tag"
                  rules={[
                    {
                      required: true,
                      message: "Please select a tag",
                    },
                  ]}
                  initialValue={selectedMerchantData?._tag_id || ""}
                >
                  <Select
                    className="report-dropdown"
                    style={{ width: 150 }}
                    defaultValue={selectedMerchantData?._tag_id}
                    onChange={this.handleDropdownItemClick}
                    options={tagInfo.map((data) => ({
                      label: data.name,
                      value: data.bill_tracker_tag_id,
                    }))}
                  />
                </Form.Item>

                <Form.Item name="contact_phone_number" label="Phone number">
                  <Input
                    placeholder="Phone number"
                    type="text"
                    value={selectedMerchantData?.contact_phone_number || ""}
                  />
                </Form.Item>

                <Form.Item name="contact_email" label="Email">
                  <Input
                    placeholder="Email"
                    type="text"
                    value={selectedMerchantData?.contact_email || ""}
                  />
                </Form.Item>

                <Form.Item name="website" label="Website">
                  <Input
                    placeholder="Website"
                    type="text"
                    value={selectedMerchantData?.website || ""}
                  />
                </Form.Item>

                <Form.Item name="cancel_instruction" label="Cancel instruction">
                  <Input
                    placeholder="Cancel instruction"
                    type="text"
                    value={selectedMerchantData?.cancel_instruction || ""}
                  />
                </Form.Item>
              </div>

              <Button
                className="text-gray"
                htmlType="submit"
                type="primary"
                block
              >
                Save
              </Button>
            </Form>
          </CustomModal>
        )}
        <CustomModal
          className={"signup-setting-modal"}
          visible={deleteModal}
          onClose={() => {
            this.handleDeleteModal(null);
          }}
        >
          <p className="txt-title">Delete this entry?</p>
          <p className="txt-info">This change cannot be undone.</p>

          <Button type="primary" block onClick={this.deleteActionSubmit}>
            Delete
          </Button>
        </CustomModal>
      </BasicLayout>
    );
  }
}

export default connect(
  (state) => ({
    isLoggedIn: state.isLoggedIn,
    merchantListingInfo: state.merchantListingInfo,
    tagInfo: state.tagInfo,
    admin: state.adminInfo,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(index));
