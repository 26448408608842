import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as actions from "./actions";
import * as API from "./services/api";
import { Route, Switch, withRouter } from "react-router-dom";
import routes from "./routes";
import Loading from "./components/loading";
import LoginPage from "./containers/auth/index";
import DashboardPage from "./containers/dashboard/index";
import "./App.less";

class App extends Component {
  componentDidMount() {
    const { history } = this.props;
    this.checkAdminInfo();
    console.log("componentDidMount ==>");

    this.props.setRoute(history.location.pathname);
    this.props.history.listen((location, action) => {
      window.scrollTo(0, 0);

      const path = history?.location?.pathname || "";
      this.props.setRoute(path);
    });
  }

  componentWillReceiveProps(nextProps) {
    console.log("componentWillReceiveProps ==>");
    const m = nextProps.route.match(/^\/([^/]+)/);
    document.body.className = m && m[1];
    if (nextProps.route !== "/login" && nextProps.route !== this.props.route) {
      this.checkAdminInfo();
    }
  }

  // Check weather user is already login or not and redirect base on it.
  checkAdminInfo = () => {
    const token = API.tokenStorage.get();
    if (token && token.length && token !== "null") {
      API.getAdminProfile()
        .then((result) => {
          // Login Success
          if (result.status) {
            this.props.setAdminInfo(result.data);
          } else {
            this.props.logout();
          }
        })
        .catch(() => {
          this.props.logout();
        });
    } else {
      this.props.history.push("/login");
    }
  };

  render() {
    const token = API.tokenStorage.get();
    return (
      <div className="app">
        <section className="content">
          <Switch>
            {routes.map((route, i) => {
              let newRoute = { ...route };
              if (newRoute.oldPath === "/" || newRoute.oldPath === "/login") {
                if (token && token.length && token !== "null") {
                  newRoute.component = DashboardPage;
                } else {
                  newRoute.component = LoginPage;
                }
              }
              return <Route {...newRoute} key={i} />;
            })}
          </Switch>
        </section>

        <Loading />
      </div>
    );
  }
}

export default connect(
  (state) => ({
    route: state.route,
    isLoggedIn: state.isLoggedIn,
    isLoading: state.isLoading,
  }),
  (dispatch) => bindActionCreators({ ...actions }, dispatch)
)(withRouter(App));
